<template>
  <section class="min-height-77vh d-flex justify-content-center align-items-center">
    <div>
      <b-row
        class="justify-content-center"
      >
        <div class="mb-2">
          <h3>
            Choose Your Plan
          </h3>
        </div>
      </b-row>
      <b-row
        class="justify-content-center"
      >
        <div
          v-for="subscriptionPackage in packages"
          :key="subscriptionPackage.id"
        >
          <b-col
            class="d-flex justify-content-center align-items-center"
          >
            <b-card
              class="text-center"
            >
              <b-card-text class="d-flex flex-column justify-center align-center">
                <h3 class="mx-5">
                  {{ subscriptionPackage.name.toUpperCase() }}
                </h3>
                <div
                  class="small position-absolute pr-4"
                >
                  <b-badge
                    v-show="subscriptionPackage.id === activePackageId"
                    variant="light-primary"
                  >
                    Active
                  </b-badge>
                </div>
                <div
                  class="d-flex justify-content-center"
                >
                  <img
                    src="@/assets/images/pages/Pot1.svg"
                    class="align-self-center my-1"
                  >
                </div>
                <span>A simple start for everyone</span>
              </b-card-text>
              <b-card-text>
                <div>
                  <sup class="font-weight-bolder h5 text-primary">$</sup>
                  <span class="font-weight-bolder h1 text-primary">{{ subscriptionPackage.price }}</span>
                  <span class="font-weight-bolder">&nbsp;/ Order</span>
                </div>
              </b-card-text>
              <b-card-text>
                <div class="font-weight-bold mb-1">
                  <feather-icon
                    icon="CheckIcon"
                    class="text-primary"
                  />
                  Per Month
                </div>
                <div
                  v-for="feature in subscriptionPackage.features"
                  :key="feature.name"
                >
                  <div class="font-weight-bold mb-1 text-wrap">
                    <feather-icon
                      icon="CheckIcon"
                      class="text-primary"
                    />
                    {{ feature.count }} {{ feature.name }}
                  </div></div>
              </b-card-text>
              <b-card-text>
                <b-button
                  v-if="subscriptionPackage.id === activePackageId"
                  variant="gradient-primary"
                  class="w-100"
                  @click="onClickButton(subscriptionPackage.id)"
                >
                  Dectivate
                </b-button>
                <b-button
                  v-else
                  variant="gradient-primary"
                  class="w-100"
                  @click="onClickButton(subscriptionPackage.id)"
                >
                  Activate
                </b-button>
              </b-card-text>
            </b-card>
          </b-col>
        </div>
      </b-row>
    </div>
  </section>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
  BCardText,
  BBadge,
  BButton,
} from 'bootstrap-vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'

const subscriptionRepository = RepositoryFactory.get('subscription')

export default {
  components: {
    BCol,
    BRow,
    BCard,
    BCardText,
    BBadge,
    BButton,
  },
  data() {
    return {
      activePackageId: null,
      packages: [],
    }
  },
  async mounted() {
    this.activePackageId = Number(this.$route.params.activePackageId)
    await this.fetchSubscriptionPackages()
  },
  methods: {
    async fetchSubscriptionPackages() {
      const { data } = (await subscriptionRepository.getSubscriptionPackageList()).data
      this.packages = data
    },
    onClickButton(id) {
      this.$swal({
        title: 'Are you sure?',
        text: (this.activePackageId === id ? 'This will affect your current activities!' : ''),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: (this.activePackageId === id ? 'Yes, deactivate it!' : 'Yes, activate it!'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.activePackageId = this.activePackageId === id ? null : id
          this.$swal({
            icon: 'success',
            title: (this.activePackageId === id ? 'Activated' : 'Deactivated'),
            text: (this.activePackageId === id ? 'Your package has been Activated.' : 'Your package has been Dectivated.'),
            customClass: {
              confirmButton: 'btn btn-success',
            },
          }).then(result1 => {
            if (result1.value) {
              this.$router.push({
                name: 'my-subscription',
              })
            }
          })
        }
      })
    },
  },
}
</script>
